export default {
  data() {
    return {
      draggableIndex: -2,
      dragTop: 0,
      dragWidth: '',
      positionsChanged: false,
      reorderedList: [],
    }
  },
  methods: {
    drag(key, event) {
      if (this.filteredItems.length > 1) {
        this.draggableIndex = key
        this.reorderedList = this.filteredItems
        this.moveAt(event)
      }
    },
    moveAt(event) {
      if (this.draggableIndex !== -2) {
        let reorderedList = []
        let lastI = -1
        // Get all rendered list items and their coordinates
        if (this.$refs.items)
          this.$refs.items.forEach((elem, i) => {
            // Ignore dragging element
            if (i !== this.draggableIndex) {
              if (elem.offsetTop < event.pageY && i > this.draggableIndex) {
                // If we are here, that means that element moved up
                reorderedList[i - 1] = this.filteredItems[i]
                if (lastI === -1 || lastI < i) {
                  lastI = i
                }
              } else if (elem.offsetTop > event.pageY && i < this.draggableIndex) {
                // If we are here, that means that element moved down
                reorderedList[i + 1] = this.filteredItems[i]
                if (lastI === -1 || lastI > i) {
                  lastI = i
                }
              } else {
                // Otherwise position doesn't change
                reorderedList[i] = this.filteredItems[i]
              }
            }
          })
        // If positions changed - we should reassign items
        if (lastI !== -1) {
          this.positionsChanged = true
          reorderedList[lastI] = this.filteredItems[this.draggableIndex]
          this.reorderedList = Object.assign([], reorderedList, reorderedList)
        }
        this.dragTop = event.pageY - 23
        this.dragWidth = event.currentTarget.parentNode.offsetWidth
      }
    },
    dragStop() {
      if (this.filteredItems.length > 1) {
        // Return all markers to initial values
        this.draggableIndex = -2
        this.dragTop = 0

        this.filteredItems = Object.assign([], this.reorderedList, this.reorderedList)
        if (this.positionsChanged) this.saveNewPositions()
      }
    },
    isDragging(key) {
      return this.draggableIndex === key
    },
    isDraggingPrevious(key) {
      if (this.draggableIndex === key - 1) return '68px'
    },
    positionByKey(key) {
      return this.isDragging(key) ? 'absolute' : 'relative'
    },
    topByKey(key) {
      return this.isDragging(key) ? this.dragTop + 'px' : 0
    },
    widthByKey(key) {
      if (this.isDragging(key)) return this.dragWidth + 'px'
    },
    zByKey(key) {
      if (this.isDragging(key)) return '10'
    },
  },
}
