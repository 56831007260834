<template v-on:keyup.native.enter="onSave()">
  <transition name="sub-step-modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <h2 class="modal__header">Ny Inventar type</h2>
          <div class="modal__input-card">
            <div class="input__container input__container--full-width">
              <label class="input__label">Inventar navn</label>
              <input @keyup.enter.prevent="onSave()" class="input" type="text" placeholder="Indtast inventar navn" v-model="step.name" />
            </div>
          </div>
          <div class="modal__options">
            <span class="modal__cancel" @click="$emit('close')">Annuller</span>
            <button class="button button--action" @click="onSave()" :disabled="isSaving">Gem</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      step: {
        name: '',
        slug: '',
        instances: 1,
        selected: false,
      },
      isSaving: false,
    }
  },
  methods: {
    ...mapActions(['createOptionalStep']),
    async onSave() {
      this.isSaving = true
      this.step.slug = this.slugify(this.step.name)
      await this.createOptionalStep(this.step).catch(() => this.errorNotification())
      this.$emit('close')
      this.succesNotification()
      this.isSaving = false
    },
    succesNotification() {
      this.$notify({
        type: 'success',
        title: 'Succes! ',
        text:
          'Du har gemt inventar typen <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
      this.isSaving = false
    },
    errorNotification() {
      this.$notify({
        type: 'error',
        title: 'Fejl!',
        text: 'Noget gik galt',
      })
      this.isSaving = false
    },
    slugify(string) {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')

      return (
        string
          .toString()
          .toLowerCase()
          // eslint-disable-next-line no-useless-escape
          .replace(/\s+/g, '-')
          .replace(p, c => b.charAt(a.indexOf(c)))
          // eslint-disable-next-line no-useless-escape
          .replace(/&/g, '-and-')
          // eslint-disable-next-line no-useless-escape
          .replace(/[^\w\-]+/g, '')
          // eslint-disable-next-line no-useless-escape
          .replace(/\-\-+/g, '-')
          // eslint-disable-next-line no-useless-escape
          .replace(/^-+/, '')
          // eslint-disable-next-line no-useless-escape
          .replace(/-+$/, '')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/button.scss';
@import '@/assets/shared-styles/input.scss';
@import '@/assets/shared-styles/admin/modal.scss';

.sub-step-modal-enter {
  opacity: 0;
}
.sub-step-modal-leave-active {
  opacity: 0;
}
.sub-step-modal-enter .sub-step-modal__container,
.sub-step-modal-leave-active .sub-step-modal__container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
