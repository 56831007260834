<template>
  <transition name="item-modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <h2 class="modal__header">Nyt felt</h2>
          <div class="modal__input-card">
            <div class="input__container input__container--full-width">
              <label class="input__label">Navn</label>
              <input class="input" type="text" placeholder="Indtast navn" v-model="item.name" />
            </div>
            <div class="input__container input__container--full-width">
              <label class="input__label">Placeholder</label>
              <input class="input" type="text" placeholder="Indtast placeholder" v-model="item.placeholder" />
            </div>
            <div class="textarea__container textarea__container--padding-top">
              <label class="textarea__label">Info</label>
              <textarea rows="6" class="textarea" placeholder="Indtast info..." type="text" v-model="item.info"></textarea>
            </div>
            <div class="select-container select-container--full-width">
              <label for="type">Type</label>
              <select id="type" required v-model="item.type">
                <option value="" disabled selected>Vælg felt type</option>
                <option v-for="type in fieldTypes" :value="type.id" :key="type.id">
                  {{ type.name }}
                </option>
              </select>
              <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
            </div>

            <template v-if="item.type == 'unit'">
              <div class="input__container input__container--full-width">
                <label class="input__label">Pris</label>
                <input class="input" type="number" placeholder="Indtast pris" v-model.number="item.price" />
              </div>
              <div class="input__container input__container--full-width">
                <label class="input__label">Enhed</label>
                <input class="input" type="text" placeholder="Indtast enhed (fx. mm)" v-model="item.unit" />
              </div>
              <div class="input__container">
                <label class="input__label">Min antal</label>
                <input class="input" type="number" placeholder="Indtast minimum antal" v-model.number="item.minAmount" />
              </div>
              <div class="input__container">
                <label class="input__label">Max antal</label>
                <input class="input" type="number" placeholder="Indtast maximum antal" v-model.number="item.maxAmount" />
              </div>
            </template>

            <div class="add-custom" v-if="item.type == 'custom'">
              <div class="add-custom__input">
                <div class="input__container input__container--without-label">
                  <input class="input" type="text" placeholder="Indtast custom felt" v-model="customOption" />
                </div>
                <button @click="addCustomOption(customOption)" class="button button--grey button--thin button--with-icon">
                  <!-- prettier-ignore -->
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="#999"/></svg>
                  Tilføj
                </button>
              </div>
              <div class="add-custom__options">
                <div class="add-custom__option" v-for="(customOption, index) in item.customOptions" :key="index">
                  <svg @click="removeCustomOption(customOption)" width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l9 9m0-9l-9 9" stroke="#474746" /></svg>
                  <span class="add-custom__option-label">{{ customOption.name }}</span>
                  <div class="input__container input__container--without-label">
                    <input class="input" type="number" v-model.number="customOption.multiplier" />
                    <span class="input__unit">%</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-custom" v-if="item.type == 'customFixed'">
              <div class="add-custom__input">
                <div class="input__container input__container--without-label">
                  <input class="input" type="text" placeholder="Indtast custom felt" v-model="customOptionFixed" />
                </div>
                <button @click="addCustomOptionFixed(customOptionFixed)" class="button button--grey button--thin button--with-icon">
                  <!-- prettier-ignore -->
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="#999"/></svg>
                  Tilføj
                </button>
              </div>
              <div class="add-custom__options">
                <div class="add-custom__option" v-for="(customOptionFixed, index) in item.customOptionsFixed" :key="index">
                  <svg @click="removeCustomOptionFixed(customOptionFixed)" width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l9 9m0-9l-9 9" stroke="#474746" /></svg>
                  <span class="add-custom__option-label">{{ customOptionFixed.name }}</span>
                  <div class="input__container input__container--without-label">
                    <input class="input" type="number" v-model.number="customOptionFixed.price" />
                    <span class="input__unit">kr</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__options">
            <span class="modal__cancel" @click="$emit('close')">Annuller</span>
            <button class="button button--action" @click="onSave()" :disabled="isSaving">Gem</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      fieldTypes: [
        { id: 'unit', name: 'Dropdown med antal' },
        { id: 'custom', name: 'Dropdown med tekst (procent)' },
        { id: 'customFixed', name: 'Dropdown med tekst (pris)' },
      ],
      customOption: '',
      customOptionFixed: '',
      isSaving: false,
    }
  },
  props: {
    isNew: Boolean,
    item: Object,
  },
  methods: {
    ...mapActions(['createItem', 'updateItem']),
    async onSave() {
      this.isSaving = true
      if (this.isNew) {
        await this.createItem(this.item).catch(() => this.errorNotification())
        this.$emit('close')
        this.succesNotification()
      } else {
        await this.updateItem(this.item).catch(() => this.errorNotification())
        this.$emit('close')
        this.succesNotification()
      }
    },
    succesNotification() {
      this.$notify({
        type: 'success',
        title: 'Succes! ',
        text:
          'Du har gemt feltet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
      this.isSaving = false
    },
    errorNotification() {
      this.$notify({
        type: 'error',
        title: 'Fejl!',
        text: 'Noget gik galt',
      })
      this.isSaving = false
    },
    addCustomOption(customOption) {
      this.item.customOptions.push({ name: customOption, multiplier: '' })
      this.customOption = ''
    },
    removeCustomOption(selected) {
      this.item.customOptions = this.item.customOptions.filter(customOption => customOption !== selected)
    },
    addCustomOptionFixed(customOptionFixed) {
      if (!this.item.customOptionsFixed) this.item.customOptionsFixed = []
      this.item.customOptionsFixed.push({ name: customOptionFixed, price: '' })
      this.customOptionFixed = ''
    },
    removeCustomOptionFixed(selected) {
      this.item.customOptionsFixed = this.item.customOptionsFixed.filter(customOptionFixed => customOptionFixed !== selected)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/button.scss';
@import '@/assets/shared-styles/input.scss';
@import '@/assets/shared-styles/select.scss';
@import '@/assets/shared-styles/textarea.scss';
@import '@/assets/shared-styles/admin/modal.scss';

.add-custom__input {
  display: flex;
  margin-bottom: 1.25rem;
  > button {
    margin-left: 1rem;
  }
  .input__container {
    flex-grow: 1;
  }
}
.add-custom__options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  > * {
    margin-bottom: 0.5rem;
  }
}

.add-custom__option {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 0.8125rem;
  background-color: #e8e7e7;
  color: #474746;
  border: 1px solid #e8e7e7;
  padding: 0.25rem 0.75rem;
  > svg {
    cursor: pointer;
    margin-right: 0.5rem;
    &:hover {
      & path {
        stroke: #333;
      }
    }
  }
  .input {
    width: 70px;
    padding: 0.25rem 0.5rem;
  }
}

.add-custom__option-label {
  flex-grow: 1;
}

.item-modal-enter {
  opacity: 0;
}
.item-modal-leave-active {
  opacity: 0;
}
.item-modal-enter .item-modal__container,
.item-modal-leave-active .item-modal__container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.textarea {
  border: none;
}
</style>
