<template>
  <div v-on:mousemove="moveAt">
    <div
      v-for="(item, index) in filteredItems"
      :key="index"
      ref="items"
      :style="{
        position: positionByKey(index),
        top: topByKey(index),
        width: widthByKey(index),
        'z-index': zByKey(index),
        'margin-top': isDraggingPrevious(index),
      }"
    >
      <div class="step" :class="{ 'step--removing': removing(item.id) }">
        <!-- prettier-ignore -->
        <svg v-on:mousedown="drag(index, $event)" v-on:mouseup="dragStop" class="step__order-icon" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z" fill="#474746"/></svg>
        <span class="step__title">{{ item.name }}</span>
        <span v-if="itemType == 'subStep'" class="step__label" @click="onShowItemModal(name, false, item)">Rediger</span>
        <router-link v-if="itemType == 'step'" :to="{ name: 'calculator-builder-step', params: { step: item.slug } }" class="step__label">Se detaljer</router-link>
        <span class="step__remove" @click="onRemoveItem(item.id)">Fjern</span>
      </div>
    </div>
    <div v-if="itemType == 'subStep' && !isLoading" class="new-step" @click="onShowItemModal(name, true, '')">
      <span>Tilføj ny felt</span>
    </div>
    <div v-if="itemType == 'step' && !isLoading" class="new-step" @click="showStepModal = true">
      <span>Tilføj ny inventar type</span>
    </div>

    <ItemModal v-if="itemType == 'subStep' && showItemModal" :item="item" :isNew="newItem" @close="showItemModal = false"></ItemModal>
    <StepModal v-if="itemType == 'step' && showStepModal" @close="showStepModal = false"></StepModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ItemModal from '@/components/admin/ItemModal.vue'
import StepModal from '@/components/admin/StepModal.vue'

import dragNDrop from '@/mixins/dragNDrop'

export default {
  data() {
    return {
      showItemModal: false,
      showStepModal: false,
      newItem: false,
      item: '',
      filteredItems: [],
      isRemoving: false,
      removingId: false,
    }
  },
  mixins: [dragNDrop],
  components: {
    StepModal,
    ItemModal,
  },
  props: {
    isLoading: Boolean,
    name: String,
    itemType: String,
  },
  async created() {
    if (this.itemType == 'subStep') {
      this.filteredItems = await this.itemsBySubStep(this.name)
    } else if (this.itemType == 'step') {
      if (this.optionalSteps.length == 0) await this.getOptionalSteps()
      this.filteredItems = this.optionalSteps
    }
  },
  computed: {
    ...mapGetters(['itemsBySubStep', 'optionalSteps', 'items']),
  },
  methods: {
    ...mapActions(['updateItems', 'updateOptionalStep', 'deleteItem', '', 'getOptionalSteps', 'getItems']),
    saveNewPositions() {
      const updatedItems = this.filteredItems.map((item, index) => {
        item.index = index + 1
        return item
      })

      if (this.itemType == 'subStep') this.updateItems(updatedItems)
      if (this.itemType == 'step') this.updateOptionalStep(updatedItems)
      this.positionsChanged = false
    },
    removing(id) {
      return this.removingId == id ? true : false
    },
    onShowItemModal(subStep, isNew, item) {
      this.newItem = isNew
      const defaultItem = {
        index: 99,
        name: '',
        placeholder: '',
        price: '',
        type: '',
        unit: '',
        customOptions: [],
        customOptionsFixed: [],
        minAmount: 1,
        maxAmount: 10,
        amount: '',
        info: '',
        customOption: '',
        customOptionFixed: '',
        step: this.$route.params.step,
        subStep: subStep,
      }

      isNew ? (this.item = defaultItem) : (this.item = item)
      this.showItemModal = true
    },
    async onRemoveItem(id) {
      this.$confirm({
        message: `Er du sikker på at du vil slette dette felt?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: async confirm => {
          if (confirm) {
            this.isRemoving = true
            this.removingId = id
            await this.deleteItem(id).catch(() => this.deleteError())
            this.deleteSucces()
            this.isRemoving = false
          }
        },
      })
    },
    deleteSucces() {
      this.$notify({
        type: 'success',
        title: 'Succes! ',
        text:
          'Feltet er slettet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/admin/step.scss';
</style>
